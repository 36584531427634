<template>
    <header :class="{'border-b border-[#EBE7E6]': from === 'maslow', 'shadow-lg': from === 'valority' && !(pageResultat === true && isMobile === false)}"
        :style="from === 'valority' && (pageResultat === true && isMobile === false) ? colorStyles.bg.action : ''"
    >
        <div class="flex items-center h-menu"
             :class="{
                'max-w-container md:max-w-md sm:max-w-sm mx-auto justify-between': from === 'valority',
                '!justify-center text-white': pageResultat === true,
                'px-6 justify-between': from === 'maslow'
            }"
        >
            <div class="flex items-center">
                <div class="mr-[3.068rem]">
                    <nuxt-link v-if="from === 'maslow'" class="cursor-pointer" :to="{name: 'index'}">
                        <logos-full class="w-[4.5rem] h-[1.25rem]"></logos-full>
                    </nuxt-link>

                    <a v-else-if="from === 'valority'"
                       class="cursor-pointer"
                       :class="{'text-black': pageResultat === true && isMobile === true}"
                       href="https://www.valority.com"
                    >
                        <logos-valority class="w-[7.56rem]"></logos-valority>
                    </a>
                </div>

                <div v-if="pageResultat === false" class="text-base font-medium select-none sm:hidden" :style="from === 'maslow' ? colorStyles.text.primary : ''">
                    Estimation de votre capacité d’investissement
                </div>
            </div>

            <div>
                <div v-if="from === 'maslow'" class="flex">
                    <c-button v-if="!isLoggedIn" class="ml-5 bg-tangerine text-white text-body-14" :to="{name: 'mon-compte'}">Mon compte</c-button>

                    <div v-else class="ml-5 sm:ml-0 flex gap-5 sm:gap-3 mr-10 sm:mr-0 md:mr-0 relative cursor-default leading-[4rem]">
                        <NuxtLink
                            :to="{name: 'mon-compte-mon-panier'}"
                            class="flex hover:text-tangerine"
                            exact-active-class="link-is-active"
                        >
                            <icons-shopping-cart-outline :active="useUserStore().hasInShoppingCart" class="mt-0.5 mr-1.5 w-6"></icons-shopping-cart-outline>
                            <span class="block sm:hidden">Mon panier</span>
                        </NuxtLink>

                        <NuxtLink
                            :to="{name: 'mon-compte'}"
                            class="flex hover:text-tangerine"
                            active-class="link-is-active"
                        >
                            <icons-user-outline class="mt-0.5 mr-1.5 w-6"></icons-user-outline>
                            <span class="block sm:hidden">{{ prenom }}</span>
                        </NuxtLink>
                    </div>
                </div>

                <div v-else-if="from === 'valority' && pageResultat === false">
                    <c-button
                        class="sm:hidden flex items-center text-base text-grey7 border border-grey7 rounded-full sm:!px-3 sm:!py-4 ml-3 bg-white btn-valority-inverted"
                        @click="useNuxtApp().$emit('clear-simu')"
                    >
                        <icons-refresh-outline class="w-5 mr-2"></icons-refresh-outline>
                        Réinitialiser le simulateur
                    </c-button>
                </div>
            </div>
        </div>
    </header>
</template>

<script setup>
import {useUserStore} from '~/store/user';
import {storeToRefs} from "pinia";
import {useNuxtApp} from "#app";

const userStore = useUserStore();

const {isLoggedIn, prenom} = storeToRefs(userStore);

const isMobile = ref(useNuxtApp().$isMobile);
</script>

<script>
import {useUserStore} from "~/store/user";

export default {
    name: "HeaderSimulation",
    emits: ['clear-simu'],
    props: {
        colorStyles: {
            type: Object,
            required: true
        },
        from: {
            type: String,
            required: true
        },
        pageResultat: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            selected: false,
        }
    },
    methods: {
        selectedMenu(type) {
            if (type === this.selected) {
                this.selected = false;

                return;
            }

            this.selected = type;
        },
        logout() {
            useUserStore().logout();
            window.location.href = useNuxtApp().$axios.defaults.baseURL + "/logout";
        }
    }
}
</script>

<style scoped>

</style>

<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="121" height="17" viewBox="0 0 121 17" fill="none">
        <g clip-path="url(#clip0_14269_26667)">
            <mask id="mask0_14269_26667" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="0" y="0" width="121" height="17">
                <path d="M121 0H0V17H121V0Z" fill="white"/>
            </mask>
            <g mask="url(#mask0_14269_26667)">
                <path d="M2.21747 0.236084L5.2125 9.57545C5.42585 10.2227 5.65831 10.9354 5.90989 11.7136C6.16146 12.4917 6.39393 13.2132 6.60728 13.8783H6.65251L8.02479 9.57545L11.0654 0.236084H13.2602L7.59021 16.7637H5.5556L0 0.236084H2.21747Z" fill="currentColor"/>
                <path d="M16.4611 10.2577H21.1935L20.2337 7.39806C19.9743 6.64621 19.7381 5.92026 19.525 5.22021C19.3118 4.52015 19.0984 3.80307 18.8847 3.06897H18.8394C18.6259 3.80345 18.4048 4.51174 18.1763 5.19383C17.9478 5.87592 17.7037 6.61066 17.444 7.39806L16.4611 10.2577ZM17.9702 0.236084H19.8449L25.5832 16.7637H23.3887L21.8112 12.1723H15.8896L14.3347 16.7637H12.1628L17.9702 0.236084Z" fill="currentColor"/>
            </g>
            <path d="M27.4353 0.236084V16.7637H35.6889V14.7174H29.5387V0.236084H27.4353Z" fill="currentColor"/>
            <mask id="mask1_14269_26667" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="0" y="0" width="121" height="17">
                <path d="M121 0H0V17H121V0Z" fill="white"/>
            </mask>
            <g mask="url(#mask1_14269_26667)">
                <path d="M39.0264 8.50011C39.0088 9.48232 39.1281 10.4622 39.3807 11.412C39.5751 12.1476 39.9045 12.8413 40.3524 13.4583C40.7295 13.9724 41.228 14.3869 41.804 14.6652C42.3574 14.9269 42.9631 15.0614 43.5761 15.0587C44.1818 15.063 44.7803 14.9283 45.3249 14.6652C45.8892 14.3833 46.3758 13.9691 46.7424 13.4583C47.1824 12.8398 47.504 12.1461 47.6912 11.412C47.9357 10.4609 48.0511 9.48159 48.0341 8.50011C48.0341 7.34593 47.916 6.3577 47.6798 5.53543C47.4869 4.80338 47.1573 4.11377 46.7081 3.5024C46.3297 2.99601 45.831 2.59044 45.2563 2.32188C44.7006 2.06854 44.0959 1.93867 43.4844 1.94131C42.8751 1.93792 42.2731 2.07261 41.7242 2.33512C41.1571 2.61291 40.6696 3.02797 40.3066 3.54203C39.8734 4.163 39.5559 4.85606 39.3692 5.58831C39.1248 6.53946 39.0095 7.51865 39.0264 8.50011ZM43.576 0.000112231C44.5033 -0.00945613 45.4222 0.173752 46.2739 0.53795C47.1014 0.900219 47.833 1.44807 48.4112 2.13844C49.013 2.84658 49.4816 3.72969 49.8169 4.78778C50.1522 5.84587 50.3199 7.08331 50.3199 8.50011C50.3199 9.88196 50.1447 11.1019 49.7943 12.1598C49.491 13.1311 49.0019 14.0352 48.3538 14.8226C47.7682 15.5215 47.0291 16.0779 46.1931 16.4491C45.3387 16.8105 44.4198 16.9979 43.491 17.0002C42.5623 17.0025 41.6425 16.8196 40.7862 16.4624C39.9585 16.1004 39.2269 15.5525 38.6489 14.8619C38.0464 14.1538 37.5776 13.2706 37.2427 12.2126C36.9079 11.1545 36.7402 9.9171 36.7397 8.50045C36.7397 7.11913 36.915 5.89923 37.2656 4.84077C37.5687 3.86941 38.0578 2.96527 38.7062 2.17796C39.2915 1.47909 40.0304 0.92267 40.8661 0.551422C41.7198 0.179146 42.6436 -0.00877416 43.576 0.000112231Z" fill="currentColor"/>
                <path d="M55.1898 8.31666H56.9275C57.3716 8.3204 57.814 8.26294 58.2422 8.14592C58.6248 8.04317 58.9825 7.8647 59.2939 7.62121C59.6017 7.37145 59.8448 7.05215 60.0028 6.69001C60.1887 6.25022 60.2783 5.77622 60.2656 5.29945C60.2656 4.23275 60.0103 3.44573 59.4996 2.93839C58.9889 2.43105 58.2459 2.17749 57.2706 2.17772H55.1898V8.31666ZM53.2007 0.236292H57.8877C58.5652 0.223136 59.2383 0.348193 59.8652 0.603719C60.4137 0.832382 60.9025 1.18227 61.2943 1.62675C61.6862 2.08417 61.9816 2.61501 62.1629 3.18783C62.3666 3.82827 62.467 4.49666 62.4602 5.16822C62.4678 5.74616 62.383 6.32164 62.209 6.87322C62.0581 7.34638 61.8341 7.79344 61.5452 8.19845C61.2787 8.56649 60.9591 8.89357 60.5966 9.16917C60.2585 9.42814 59.8948 9.65231 59.511 9.83824L63.3064 16.7639H60.9511L57.5447 10.2842H55.1898V16.7639H53.2007V0.236292Z" fill="currentColor"/>
            </g>
            <path d="M67.5811 0.236084H65.478V16.7637H67.5811V0.236084Z" fill="currentColor"/>
            <path d="M69.6155 0.236084V2.28225H74.0281V16.7638H76.1315V2.28225H80.6354V0.236084H69.6155Z" fill="currentColor"/>
            <path d="M91.1524 0.236084L87.5399 7.63403L83.9504 0.236084H81.6414L86.3965 9.83803V16.7638H88.4999V9.83803L93.3701 0.236084H91.1524Z" fill="currentColor"/>
            <mask id="mask2_14269_26667" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="0" y="0" width="121" height="17">
                <path d="M121 0H0V17H121V0Z" fill="white"/>
            </mask>
            <g mask="url(#mask2_14269_26667)">
                <path d="M107.261 4.62121C106.622 4.00512 106.039 3.25938 104.995 3.3383C104.304 3.39027 103.778 3.75725 103.315 4.23133C102.733 4.82911 102.251 5.54702 102.576 6.36607C102.852 7.06489 103.408 7.65481 103.896 8.37496C103.322 9.48274 103.285 10.7866 103.57 11.9425C102.464 10.8985 101.198 9.82076 100.082 8.60678C99.237 7.69006 99.1684 6.48023 99.3921 5.2813C99.8876 2.62747 102.333 0.414483 105.033 0.183564C106.288 0.0755701 107.394 0.423689 108.298 1.32862C108.68 1.7103 109.074 2.08076 109.434 2.42753C108.697 3.17159 108.001 3.87569 107.262 4.62132" fill="currentColor"/>
                <path d="M115.94 0.278513C114.386 -0.0395204 113.024 0.295576 111.874 1.46027C109.791 3.57413 107.674 5.65487 105.559 7.73775C104.844 8.42268 104.418 9.3517 104.368 10.3366C104.184 13.1581 106.586 16.085 109.52 16.6047C111.07 16.8808 112.407 16.5187 113.53 15.3648C113.66 15.2319 113.79 15.1 113.92 14.9679L111.678 12.853C111.658 12.8721 111.638 12.8921 111.619 12.9109C111.217 13.2991 110.73 13.5738 110.157 13.526C108.942 13.4259 108.176 12.677 107.703 11.6413C107.281 10.7194 107.678 9.95432 108.349 9.29143C109.366 8.2846 110.382 7.2764 111.397 6.2668C112.145 5.52398 112.884 4.77217 113.644 4.04001C114.585 3.13329 115.639 3.09321 116.649 3.92102C116.894 4.12166 117.115 4.34951 117.307 4.60008C118.042 5.56203 117.992 6.5259 117.165 7.4077C116.942 7.62523 116.709 7.83149 116.465 8.0257C117.234 8.78053 117.943 9.47441 118.727 10.2424C119.113 9.84251 119.518 9.4194 119.926 9.00056C120.6 8.31569 120.984 7.4002 120.997 6.44294C121.091 3.62218 118.845 0.873603 115.94 0.278625" fill="#A58862"/>
            </g>
        </g>
        <defs>
            <clipPath id="clip0_14269_26667">
                <rect width="121" height="17" fill="white"/>
            </clipPath>
        </defs>
    </svg>
</template>

<script>
export default {
    name: "Valority"
}
</script>

<style scoped>

</style>
